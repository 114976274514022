import { INVALID_DATE_MESSAGE_VALUE } from './../constants/dateTimePicker-constants';
import moment, { ISO_8601 } from 'moment';
import momentTimezone from 'moment-timezone';
import { DATETIME_FORMAT } from '../constants/dateTimePicker-constants';
import { countryCodeWithTimeZoneList } from '../constants/country-constants';

export const getDayCounter = (stringValue: string) => {
  return moment(stringValue, ISO_8601).isValid() ? getDuration(stringValue) : 'Unspecified';
};

const getDuration = (stringValue: string) => {
  let dateToCompare = moment.utc(stringValue, ISO_8601);
  let dateNow = moment(moment.utc(moment.now()));
  //for UT we can only verify the string conversion, not the actual duration from current datetime. so we don't need to change the expected value in UT everytime
  if (process.env.NODE_ENV === 'test') {
    dateToCompare = moment.utc(stringValue, ISO_8601);
    dateNow = moment.utc('2022-04-27T00:25:40.6537472Z', ISO_8601);
  }

  let timeDiff = moment.duration(dateNow.diff(dateToCompare));

  let minutes = parseInt(timeDiff.asMinutes().toString());
  let seconds = parseInt(timeDiff.asSeconds().toString());

  let dateStr = 'Unspecified';
  //if minutes and seconds < 0, then it is for future date
  if (minutes <= 0) {
    if (seconds < 0) {
      dateStr = getDayCounterFomat(dateToCompare, dateNow, true);
    } else {
      dateStr = seconds + (seconds === 1 ? ' sec ago' : ' secs ago');
    }
  } else {
    dateStr = getDayCounterFomat(dateToCompare, dateNow, false);
  }

  return dateStr;
};

const getDayCounterFomat = (dateToCompare: moment.Moment, dateNow: moment.Moment, futureComparison: boolean) => {
  let timeDiff = futureComparison
    ? moment.duration(dateToCompare.diff(dateNow))
    : moment.duration(dateNow.diff(dateToCompare));

  let days = parseInt(timeDiff.asDays().toString());
  let hours = parseInt(timeDiff.asHours().toString());
  let minutes = parseInt(timeDiff.asMinutes().toString());
  let seconds = parseInt(timeDiff.asSeconds().toString());
  let dateStr = 'Unspecified';
  if (minutes < 1) {
    seconds = seconds - minutes * 60;
    dateStr = seconds + (seconds === 1 ? ' sec' : ' secs');
  } else if (hours < 1) {
    seconds = seconds - minutes * 60;
    dateStr =
      minutes +
      (minutes === 1 ? ' min' : ' mins') +
      (seconds === 0 ? '' : ', ' + seconds + (seconds === 1 ? ' sec' : ' secs'));
  } else if (hours < 24) {
    minutes = minutes - hours * 60;
    dateStr =
      hours +
      (hours === 1 ? ' hr' : ' hrs') +
      (minutes === 0 ? '' : ', ' + minutes + (minutes === 1 ? ' min' : ' mins'));
  } else {
    hours = hours - days * 24;
    dateStr =
      days + (days === 1 ? ' day' : ' days') + (hours === 0 ? '' : ', ' + hours + (hours === 1 ? ' hr' : ' hrs'));
  }
  return futureComparison ? 'in ' + dateStr : dateStr + ' ago';
};

export const getDurainFromString = (stringValue: string) => {
  let [firstDuration, minutes, seconds] = stringValue.split(':');
  let daysNumber, hoursNumber;
  if (firstDuration.includes('.')) {
    let [days, hours] = firstDuration.split('.');
    daysNumber = parseInt(days, 10);
    hoursNumber = parseInt(hours, 10);
  } else {
    hoursNumber = parseInt(firstDuration, 10);
  }
  let minutesNumber = parseInt(minutes, 10);
  let secondsNumber = parseInt(seconds, 10);
  return `${daysNumber ? daysNumber + (daysNumber > 1 ? ' days,' : ' day,') : ''} ${
    hoursNumber ? hoursNumber + (hoursNumber > 1 ? ' hrs,' : ' hr,') : ''
  } ${minutesNumber ? minutesNumber + (minutesNumber > 1 ? ' mins,' : ' min,') : ''} ${
    secondsNumber ? secondsNumber + (secondsNumber > 1 ? ' secs' : ' sec') : ''
  } `;
};

export const formatDate = (stringValue: string, inputDateFormat?: string) => {
  return moment(stringValue, inputDateFormat ? inputDateFormat : DATETIME_FORMAT.DATETIMEFORMAT).isValid()
    ? convertToLocalDate(stringValue, inputDateFormat)
    : 'Unspecified';
};

export const formatUTCDate = (stringValue: string | undefined, inputDateFormat?: string) => {
  return !!stringValue && moment(stringValue).isValid() ? moment(stringValue).utc().format(inputDateFormat) : null;
};

const convertToLocalDate = (stringValue: string, inputDateFormat?: string) => {
  return process.env.NODE_ENV === 'test' //verify format only, no local conversion for UT, so we dont need to change the expected value on UT if test executed in diff local env
    ? moment
        .utc(stringValue, inputDateFormat ? inputDateFormat : DATETIME_FORMAT.DATETIMEFORMAT)
        .format(DATETIME_FORMAT.DATETIMEFORMAT_AM_PM_MARKER)
    : moment
        .utc(stringValue, inputDateFormat ? inputDateFormat : DATETIME_FORMAT.DATETIMEFORMAT)
        .local()
        .format(DATETIME_FORMAT.DATETIMEFORMAT_AM_PM_MARKER);
};

export const compareIfDatetimeFromTheSameDate = (
  dateTimeString1: string | any,
  dateTimeString2: string | any
): boolean => {
  const date1 = new Date(dateTimeString1);
  const date2 = new Date(dateTimeString2);

  const utcDate1 = date1.toISOString().split('T')[0];
  const utcDate2 = date2.toISOString().split('T')[0];

  return utcDate1 === utcDate2;
};

export const convertDateToString = (date: Date) => {
  if (!!date) {
    if (date instanceof Date && !isNaN(date.getTime())) {
      return date.toISOString();
    }
  }
  return undefined;
};

export const convertDateStringtoUTCString = (value: string | undefined) => {
  if (value) {
    let date = new Date(value);
    return convertDateToString(date);
  } else return undefined;
};

export const convertEndDateStringtoUTCString = (value: string | undefined) => {
  if (value) {
    let date = new Date(value);
    return convertDateToString(date)?.slice(0, -7).concat('59.999Z');
  } else return undefined;
};

export const formatDateTimebyTimeZone = (timezone: string, dateToConvert: string, dateformat: string) => {
  if (!!timezone && !!dateToConvert) {
    const convertDate = momentTimezone(dateToConvert);
    return convertDate.tz(timezone).format(`${dateformat}`);
  }
};

export const ValidateDate = (input: Date) => {
  const newDate = new Date(input);
  if (newDate instanceof Date && !isNaN(newDate.getTime())) {
    return true;
  }
  return false;
};

export const combineDateAndTime = (dateValue?: string, timeValue?: string) => {
  if (dateValue === INVALID_DATE_MESSAGE_VALUE || timeValue === INVALID_DATE_MESSAGE_VALUE) {
    return '';
  }

  if (!!dateValue) {
    let date = new Date(dateValue);
    if (!!timeValue) {
      const time = new Date(timeValue);
      date.setHours(time.getHours(), time.getMinutes(), 0, 0);
    } else {
      date.setHours(0, 0, 0, 0);
    }

    return moment(date).local().toDate().toString();
  }

  return '';
};

export const getBrowserTimezone = () => {
  return `(${moment.tz(moment.tz.guess(true)).zoneAbbr()})`;
};

export const getBrowserLocalTimezone = () => {
  return moment.tz.guess();
};

export const getLastDayOfMonth = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const lastDay = new Date(year, month, 0).getDate();
  return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
};

export const getLastDayOfMonthFormatted = (dateString: string) => {
  const dateParts = dateString.split('-');
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1;
  const date = new Date(year, month, 1);

  date.setMonth(date.getMonth() + 1);
  date.setDate(0);

  const formattedDate = date.toDateString() + ' 00:00:00 GMT+1200';
  return formattedDate;
};

export const findCountryCodeByTimeZone = (timeZone: string) => {
  for (const entry of countryCodeWithTimeZoneList) {
    if (entry.timeZones.includes(timeZone)) {
      return entry.countryCode;
    }
  }
  return null;
};

export const getTodaySrartAndEndDateTime = () => {
  const today = new Date();

  const startDateTime = String(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0));

  const endDateTime = String(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999));

  return { startDateTime, endDateTime };
};

export const getYesterdaySrartAndEndDateTime = () => {
  const yesterday = new Date();

  yesterday.setDate(yesterday.getDate() - 1);
  const startDateTime = String(
    new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0, 0)
  );

  const endDateTime = String(
    new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59, 999)
  );

  return { startDateTime, endDateTime };
};

export const getThisWeekStartAndEndDateTime = () => {
  const currentDate = new Date();
  const today = new Date().getDay();

  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - today + 1);
  startDate.setHours(0, 0, 0, 0);
  const startDateTime = String(startDate);

  const endDate = new Date(currentDate);
  endDate.setDate(currentDate.getDate() + (7 - today));
  endDate.setHours(23, 59, 59, 999);
  const endDateTime = String(endDate);

  return { startDateTime, endDateTime };
};

export const getLastWeekStartAndEndDateTime = () => {
  const currentDate = new Date();

  const currentDayOfWeek = currentDate.getDay();
  const daysToLastMonday = (currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1) + 7;
  const lastMondayDate = new Date(currentDate);
  lastMondayDate.setDate(currentDate.getDate() - daysToLastMonday);
  lastMondayDate.setHours(0, 0, 0, 0);
  const startDateTime = String(lastMondayDate);

  const daysToLastSunday = currentDayOfWeek === 0 ? 0 : currentDayOfWeek;
  const lastSundayDate = new Date(currentDate);
  lastSundayDate.setDate(currentDate.getDate() - daysToLastSunday);
  lastSundayDate.setHours(23, 59, 59, 999);
  const endDateTime = String(lastSundayDate);

  return { startDateTime, endDateTime };
};

export const getThisMonthStartAndEndDateTime = () => {
  const currentDate = new Date();

  const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  firstDateOfMonth.setHours(0, 0, 0, 0);
  const startDateTime = String(firstDateOfMonth);

  const nextMonth = currentDate.getMonth() + 1;
  const lastDateOfMonth = new Date(currentDate.getFullYear(), nextMonth, 0);
  lastDateOfMonth.setHours(23, 59, 59, 999);
  const endDateTime = String(lastDateOfMonth);

  return { startDateTime, endDateTime };
};

export const getLastMonthStartAndEndDateTime = () => {
  const currentDate = new Date();

  const lastMonth = currentDate.getMonth() - 1;
  const firstDateOfLastMonth = new Date(currentDate.getFullYear(), lastMonth, 1);
  firstDateOfLastMonth.setHours(0, 0, 0, 0);
  const startDateTime = String(firstDateOfLastMonth);

  const lastDateOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  lastDateOfLastMonth.setHours(23, 59, 59, 999);
  const endDateTime = String(lastDateOfLastMonth);

  return { startDateTime, endDateTime };
};
