import React, { useEffect, useMemo, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import classNames from 'classnames';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { TransactionModel } from '../../models/transactionModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { PaginationModel } from '../../models/paginationModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { ModalType } from '../../constants/modal-constants';
import { TransactionRow } from './transaction-row';
import { TransactionHeader } from './transaction-header';
import { getBrowserZoomLevel } from '../../utilities/general-helper';
import MessageDisplay from '../../components/message-display/message-display';
import FooterBar from '../../components/footer/footer';
import EmptyList from '../../components/empty-list/empty-list';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import TransactionReceipt from './child-modal/receipt-container';
import TransactionFilterForm from './child-modal/filter-container';
import './styles/transactions.scss';

interface TransactionsProps {
  transactionsContent: TransactionModel[];
  transactionsStatus: string;
  authStatus: string;
  selectModalData: ModalStateModel;
  isReachEnd: boolean;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadTransactions: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  openModal: (data: ModalStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setPaginationDetails: (data: PaginationModel) => void;
  setIsReachEnd: (data: boolean) => void;
}

const Transactions: React.FC<TransactionsProps> = (props: TransactionsProps) => {
  const {
    transactionsContent,
    transactionsStatus,
    authStatus,
    selectModalData,
    isReachEnd,
    userAccess,
    loadTransactions,
    setHeaderConfiguration,
    openModal,
    setPageConfiguration,
    setPaginationDetails,
    setIsReachEnd,
  } = props;

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);
  const tableRef = useRef(null);
  const isLoadTerminalsRequested = useRef(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasReportReadAccess, setHasReportReadAccess] = useState(false);
  const [hasTransactionReportAccess, setHasTransactionReportAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.TRANSACTION).hasReadAccess);
    setHasReportReadAccess(userAccess(ModuleName.REPORT).hasReadAccess);
    setHasTransactionReportAccess(userAccess(ModuleName.REPORT).hasTransactionAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    getBrowserZoomLevel() <= 0.6 ? 50 : getBrowserZoomLevel() <= 0.25 ? 100 : 20
  );

  useMemo(() => {
    setLoading(transactionsStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(transactionsStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(transactionsStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, transactionsStatus]);

  useEffect(() => {
    setPageConfiguration({
      hideHeaderPadding: true,
      showFooter: true,
    } as PageSettingStateModel);
    setIsReachEnd(false);
    setPaginationDetails({
      page: 0,
      limit: rowsPerPage,
      isClickNext: false,
    });
  }, [setPageConfiguration]);

  useEffect(() => {
    if (!isLoadTerminalsRequested.current && authSuccess && hasReadAccess) {
      loadTransactions();
      isLoadTerminalsRequested.current = true;
    }
  }, [loadTransactions, authSuccess, hasReadAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Transactions',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
      hasReportReadAccess: !loading && hasReportReadAccess,
      hasTransactionReportAccess: !loading && hasTransactionReportAccess,
      showTransactionToolBar: !loading,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, error, loading]);

  const shouldHeaderBeSticky = () => {
    return window.innerWidth > 779;
  };
  const [isHeaderSticky, setIsHeaderSticky] = useState(true);

  // Update sticky header state when window width changes
  useEffect(() => {
    const handleResize = () => {
      setIsHeaderSticky(shouldHeaderBeSticky());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const Modal = useMemo(() => {
    if (selectModalData?.type === ModalType.RECEIPT) {
      return <TransactionReceipt />;
    } else {
      return <TransactionFilterForm />;
    }
  }, [selectModalData?.type]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPaginationDetails({
      page: newPage,
      limit: rowsPerPage,
      isClickNext: newPage > page,
    });
    loadTransactions();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setIsReachEnd(false);
    setPaginationDetails({
      page: 0,
      limit: +event.target.value,
      isClickNext: false,
    });
    loadTransactions();
  };

  useEffect(() => {
    if (tableRef.current) {
      (tableRef.current as HTMLElement).scrollIntoView({ behavior: 'auto' });
    }
  }, [page, rowsPerPage]);

  return (
    <>
      <div className='transaction' ref={tableRef}>
        {<>{Modal}</>}
        {(!authCheckCompleted || loading) && <LoadingDisplay />}
        {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
          <MessageDisplay
            messageTitle={Messages.NO_ACCESS_MESSAGE}
            messageContent={Messages.CONTACT_ADMIN}
          ></MessageDisplay>
        )}
        {error && <ErrorDisplayControl />}
        {success && !loading && !!transactionsContent && transactionsContent.length > 0 && (
          <div className='grid-container'>
            <TableContainer
              component='div'
              className='table-container'
              sx={{
                height: 'calc(100vh - 245px)',
                '@media (max-width: 991px)': {
                  height: 'calc(100vh - 265px)',
                },
                '@media (max-width: 850px)': {
                  height: '100%',
                },
              }}
            >
              <Table stickyHeader={isHeaderSticky} aria-label='collapsible table'>
                <TableHead className='table-header'>
                  <TransactionHeader />
                </TableHead>
                <TableBody>
                  {transactionsContent?.map((row, index) => (
                    <TransactionRow key={index} rowNumber={index} row={row} openModal={openModal} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component='div'
              className='table-pagination'
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ disabled: isReachEnd }}
            />
          </div>
        )}
        {success && !!transactionsContent && transactionsContent.length < 1 && (
          <EmptyList message='No transactions found'></EmptyList>
        )}
        {}
      </div>
      <div className='for-mobile'>
        <FooterBar
          className={classNames(
            !!transactionsContent && transactionsContent.length < 1 ? 'footer-no-content' : 'footer-with-content'
          )}
        />
      </div>
    </>
  );
};

export default Transactions;
